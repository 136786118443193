/*--------------------------------------------------------------
1.0 Custom Fonts
--------------------------------------------------------------*/
@import "fonts";

/*--------------------------------------------------------------
1.0 Common stuff
--------------------------------------------------------------*/

@import './common/index.scss';
@import './common/vars.scss';


/*--------------------------------------------------------------
1.0 Simple CSS3 Animations
--------------------------------------------------------------*/
@import "animate/animate.scss";


/*--------------------------------------------------------------
1.0 staggering animation
--------------------------------------------------------------*/
@import "animate/stagger.scss";

/* ========= GENERAL STYLING=========== */

body{
	overflow-x:hidden;  
	background-color: rgba(107,87,75,0.1);
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // font-family: raleway-medium;
    width: 100%;
    margin: 0;
    padding: 0;
}

body::-webkit-scrollbar{
	width:12px;
	background-color:#0073a5;
}
body::-webkit-scrollbar-thumb{
	background-color:#0073a5;
}
body::-webkit-scrollbar-thumb:hover{
	background-color:#c49f47;
	border:1px solid #333333;
}
body::-webkit-scrollbar-thumb:active{
	background-color:#c49f47;
	border:1px solid #333333;
}
body::-webkit-scrollbar-track{
	border:1px gray solid;
	box-shadow:0 0 2px gray inset;
}


// App's layout
#root {
    color: #333;
    background-color: #f7f7f7;
    width: 100%;
    height: 100%;

    .page-layout {
        height: 100%;

        // patch Semantic-UI .pusher
        .pusher {
            height: 100%;
            overflow: scroll;
            padding-bottom: 40px;
        }

        .pusher::-webkit-scrollbar{
            width:8px;
            background-color:#cccccc;
        }
        .pusher::-webkit-scrollbar-thumb{
            background-color:#0073a5;
        }
        .pusher::-webkit-scrollbar-thumb:hover{
            background-color:#c49f47;
            border:1px solid #333333;
        }
        .pusher::-webkit-scrollbar-thumb:active{
            background-color:#c49f47;
            border:1px solid #333333;
        }
        .pusher::-webkit-scrollbar-track{
            border:1px gray solid;
            box-shadow:0 0 2px gray inset;
        }


        .pushable > .dimmer {
            cursor: pointer;
        }

        .pushable > .pusher{
            overflow-x: auto !important;
        }

        main {
            display: flex;
            flex-direction: column;
            min-height: 100%;
        }

        // limit max-width of the .pusher class -> main is limited too
        .ui.visible.thin.left.sidebar ~ .pusher {
            @include respond-to('lg') {
                max-width: calc(100% - #{$sidebar-size})
            }
            @include respond-to('only-sm', 'only-md', 'xs'){
                overflow: hidden;
            }
        }

        .main-content {
            display: flex;
            flex-grow: 1;
            padding-top: 75px;
            padding-left: 1rem;
            padding-right: 1rem;

            .ui.container {
                @include respond-to('xs') {
                    // margin - just to fill exmpty space
                    margin: 0 auto!important;
                }
                @include respond-to('sm', 'xs') {
                    width: 100%!important;
                }

                .grid {
                    position: relative;
                    min-width: 100%;
                    min-height: 100%;
                }
            }
        }
    }
}


.container {
    margin: 2% auto;
}

.custom-button{
        border-radius: 0 !important;
    }

.small-loader{
    min-height: 90px;
}

.error{
    color: red;
}

.success{
    color: green;
}

.tiny-loader{
    color: #3fc7fa;
    margin: 0 auto;
}

.radio label{
    margin-right: 10px;
}

.inline{
    display: inline-block;
}

.ui.checkbox{
    margin-left: 10px;
}

.ui.cards > .card, .ui.card{
    border-radius: 0;
}

.blue{
   color: #3fc7fa;
}

.simple-message{
    margin: 0 auto;
    display: block;
    text-align: center;
}

.flatpickr-calendar.open{
    filter: inherit !important;
}

.no-radius{
    margin-left: 2%;    
    margin-right: 2%;
}

.zero-radius{
    margin-left: 2%;    
    margin-right: 2%;
    border-radius: 0 !important;
}

.read-more{
    color: #2185D0;
    font-size:12px;
    text-transform: lowercase;
}

.rc-tabs{
    border: none;
}

.rc-tabs-tab{
    font-size: 16px;
    margin: 0 10px;
}

.rc-tabs-tab-btn:focus{
    outline: none;
}

.rc-tabs-tabpane-active:focus{
    outline: none;
}

.rc-tabs-ink-bar{
    background-color: #21BA45;
}

.quick-page {

    margin-bottom: 80px;
    margin-top: 100px;
    font-family: gotham_light;
    font-size: 14px;

    .error {
        color: red;
        font-size: 12px;
        text-transform: lowercase;
    }

    .quick-container {
        padding: 2%;
        min-height: 450px;
        margin-bottom: 2%;
    }

    table td.value {
        font-weight: 600;
        span{
            font-weight: normal;
            font-family: gotham_light;
            font-size: 14px;
            display: block;
        }
    }

}



/*** Invoice page ***/
.invoice-content {
    background-color: #fff;
    padding: 80px 70px;
}

.invoice-content.bordered {
    border: 1px solid;
    border-color: #e7ecf1;
    background-color: rgba(231, 236, 241,0.3);
}

.invoice-content .invoice-head {
    margin-bottom: 50px;
}

.invoice-content .invoice-head .invoice-logo {
    text-align: center;
}

.invoice-content .invoice-head .invoice-logo>img {
    float: left;
    margin-right: 45px;
    width: 15%;
}

.invoice-content .invoice-head .invoice-logo>h1 {
    float: left;
    font-size: 17px;
    font-weight: 700;
    color: #39424a;
}

.invoice-content .invoice-head .company-address {
    text-align: right;
    font-size: 14px;
    color: #7e8691;
}

.invoice-content .invoice-title {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #9ea8b7;
    text-align: left;
}

.invoice-content .invoice-desc {
    font-size: 14px;
    color: #4e5a64;
    font-weight: 600;
    text-align: left;
}

.invoice-content .invoice-desc.inv-address {
    font-size: 13px;
    color: #7e8691;
    font-weight: 400;
}

.invoice-content .invoice-desc.grand-total {
    font-size: 16px;
    font-weight: 700;
}

.invoice-content .invoice-cust-add {
    margin-bottom: 50px;
}

.invoice-content .invoice-error {
    color: red;
}

.invoice-content .invoice-body .text-center {
    text-align: center;
}

.invoice-content .invoice-body tr:last-child {
    border-bottom: 1px solid #e7ecf1;
}

.invoice-content .invoice-body th,
.invoice-content .invoice-body td {
    vertical-align: middle;
    padding-left: 40px;
    text-align: left;
}

.invoice-subtotal{
    text-align: left;
}

.invoice-content .invoice-body th:first-child,
.invoice-content .invoice-body td:first-child {
    padding-left: 0;
}

.invoice-content .invoice-body th:last-child,
.invoice-content .invoice-body td:last-child {
    padding-right: 0;
}

.invoice-content .invoice-body td:first-child {
    min-width: 260px;
}

.invoice-content .invoice-body{
    table{
        tbody{
            tr td.value {
                font-weight: 600;
                color: green;
                font-family: gotham_light;
                span{
                    font-weight: normal;
                    font-family: gotham_light;
                    font-size: 14px;
                    display: block;
                }
            }
        }
    }
}

.invoice-content .invoice-body h3 {
    font-size: 14px;
    font-weight: 600;
    color: #4e5a64;
    margin-bottom: 0;
}

.invoice-content .invoice-body p {
    font-size: 13px;
    color: #7e8691;
}

.invoice-content .print-btn {
    float: right;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
}

@media (max-width: 1024px) {
    .invoice-content .invoice-head .invoice-logo>img {
        width: 130px;
        margin-top: 26px;
    }
}

@media (max-width: 991px) {
    .invoice-content {
        padding: 40px 30px;
    }
}

@media (max-width: 480px) {
    .invoice-content .invoice-head .invoice-logo>h1 {
        margin-top: 10px;
    }

    .invoice-content .invoice-head .company-address {
        font-size: 12px;
    }
}

/*End of invoice*/


.ui.list{
    font-size: 0.85em !important;
}

.ui.list > .item > .content{
    color: green;
}

.rdt_Table:parent{
    background-color: red !important;
}

.ui.tiny.buttons .button{
    font-size: 0.7rem !important;
}

.ui.left.visible.sidebar{
    box-shadow: none !important;
}