@font-face { 
				
	font-family: "HelveticaNeueLTStdLt";
	src: local('HelveticaNeueLTStdLt'), url("../fonts/helvetica/HelveticaNeueLTStdLt.otf") format("opentype");	
	font-weight: normal;
	font-style: normal;
			
}

@font-face { 
				
	font-family: "HelveticaNeue-LightCond";
	src: local('HelveticaNeue-LightCond'), url("../fonts/helvetica/HelveticaNeue-LightCond.otf") format("opentype");	
	font-weight: normal;
	font-style: normal;
			
}

@font-face { 
				
	font-family: "HelveticaNeueLT-Condensed";
	src: local('HelveticaNeueLT-Condensed'), url("../fonts/helvetica/HelveticaNeueLT-Condensed.otf") format("opentype");	
	font-weight: normal;
	font-style: normal;
			
}

@font-face { 
				
	font-family: "HelveticaNeueLT-Extended";
	src: local('HelveticaNeueLT-Extended'), url("../fonts/helvetica/HelveticaNeueLT-Extended.otf") format("opentype");	
	font-weight: normal;
	font-style: normal;
			
}


@font-face { 
				
	font-family: "HelveticaNeueLT-LightCond";
	src: local('HelveticaNeueLT-LightCond'), url("../fonts/helvetica/HelveticaNeueLT-LightCond.otf") format("opentype");	
	font-weight: normal;
	font-style: normal;
			
}


@font-face { 
				
	font-family: "HelveticaNeueLT-LightExt";
	src: local('HelveticaNeueLT-LightExt'), url("../fonts/helvetica/HelveticaNeueLT-LightExt.otf") format("opentype");	
	font-weight: normal;
	font-style: normal;
			
}

@font-face { 
				
	font-family: "HelveticaNeue-ThinCond";
	src: local('HelveticaNeueLT-LightExt'), url("../fonts/helvetica/HelveticaNeue-ThinCond.otf");	
	font-weight: normal;
	font-style: normal;
			
}


@font-face { 
				
	font-family: "HelveticaNeueLT-UltraLigCond";
	src: local('HelveticaNeueLT-UltraLigCond'), url("../fonts/helvetica/HelveticaNeueLT-UltraLigCond.otf");	
	font-weight: normal;
	font-style: normal;
			
}


@font-face { 
				
	font-family: "HelveticaNeue-Thin";
	src: local('HelveticaNeue-Thin'), url("../fonts/helvetica/HelveticaNeue-Thin.otf");	
	font-weight: normal;
	font-style: normal;
			
}


@font-face { 
				
	font-family: "HelveticaNeueLT-UltraLigExt";
	src: local('HelveticaNeueLT-UltraLigExt'), url("../fonts/helvetica/HelveticaNeueLT-UltraLigExt.otf");	
	font-weight: normal;
	font-style: normal;
			
}

@font-face { 
				
	font-family: "HelveticaNeue-ThinItalic";
	src: local('HelveticaNeue-ThinItalic'), url("../fonts/helvetica/HelveticaNeue-ThinItalic.otf");	
	font-weight: normal;
	font-style: normal;
			
}






