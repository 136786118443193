.spacer {
    flex-grow: 1;
}

.hidden {
    display: none;
}

.text-center {
    text-align: center;
}

* {
    box-sizing: border-box;
}
