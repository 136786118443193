@font-face {
    font-family: 'raleway-black';    
    src: url('../fonts/raleway/Raleway-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'raleway-blackItalic';    
    src: url('../fonts/raleway/Raleway-BlackItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'raleway-bold';    
    src: url('../fonts/raleway/Raleway-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}



@font-face {
    font-family: 'raleway-boldItalic';    
    src: url('../fonts/raleway/Raleway-BoldItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}



@font-face {
    font-family: 'raleway-extraBold';    
    src: url('../fonts/raleway/Raleway-ExtraBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}



@font-face {
    font-family: 'raleway-extraBoldItalic';    
    src: url('../fonts/raleway/Raleway-ExtraBoldItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}



@font-face {
    font-family: 'raleway-extraLight';    
    src: url('../fonts/raleway/Raleway-ExtraLight.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}



@font-face {
    font-family: 'raleway-extraLightItalic';    
    src: url('../fonts/raleway/Raleway-ExtraLightItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}



@font-face {
    font-family: 'raleway-italic';    
    src: url('../fonts/raleway/Raleway-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}



@font-face {
    font-family: 'raleway-light';    
    src: url('../fonts/raleway/Raleway-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}




@font-face {
    font-family: 'raleway-lightItalic';    
    src: url('../fonts/raleway/Raleway-LightItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}




@font-face {
    font-family: 'raleway-medium';    
    src: url('../fonts/raleway/Raleway-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}



@font-face {
    font-family: 'raleway-mediumItalic';    
    src: url('../fonts/raleway/Raleway-MediumItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}




@font-face {
    font-family: 'raleway-regular';    
    src: url('../fonts/raleway/Raleway-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}



@font-face {
    font-family: 'raleway-semiBold';    
    src: url('../fonts/raleway/Raleway-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}



@font-face {
    font-family: 'raleway-semiBoldItalic';    
    src: url('../fonts/raleway/Raleway-SemiBoldItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}



@font-face {
    font-family: 'raleway-thin';    
    src: url('../fonts/raleway/Raleway-Thin.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}



@font-face {
    font-family: 'raleway-thinItalic';    
    src: url('../fonts/raleway/Raleway-ThinItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}