@font-face {
    font-family: 'gotham_light';
    src: url('../fonts/gotham/GOTHAM-LIGHT.OTF') format('opentype');
    src: url('../fonts/gotham/GOTHAM-LIGHT.TTF') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gotham_xlight';
    src: url('../fonts/gotham/GOTHAM-XLIGHT.OTF') format('opentype');
    src: url('../fonts/gotham/GOTHAM-XLIGHT.TTF') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gotham_thin';
    src: url('../fonts/gotham/GOTHAM-THIN.OTF') format('opentype');
    src: url('../fonts/gotham/GOTHAM-THIN.TTF') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gotham_medium';
    src: url('../fonts/gotham/GOTHAM-MEDIUM.OTF') format('opentype');
    src: url('../fonts/gotham/GOTHAM-MEDIUM.TTF') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gotham_book';
    src: url('../fonts/gotham/GOTHAM-BOOK.OTF') format('opentype');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'gotham_black';
    src: url('../fonts/gotham/GOTHAM-BLACK.OTF') format('opentype');
    src: url('../fonts/gotham/GOTHAM-BLACK.TTF') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gotham_bold';
    src: url('../fonts/gotham/GOTHAM-BOLD.OTF') format('opentype');
    src: url('../fonts/gotham/GOTHAM-BOLD.TTF') format('truetype');
    font-weight: normal;
    font-style: normal;
}